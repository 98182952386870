@font-face {
  font-family: "Airbnb Cereal";
  src: url("./Assets/fonts/AirbnbCerealMedium.ttf");
}

.Roboto-Bold {
  font-family: "Airbnb Cereal" !important;
}
.ant-spin-nested-loading > div > .ant-spin{
  max-height: 100% !important;
}